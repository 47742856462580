<div id="cont">
    <p style="font-weight: 900;"><span>Proceso IP-FC-06-2022</span></p>
    <p class="paragraf-left">Información SECOP I - Proceso IP-FC-06-2022
        <span class="der">
            <a href="https://www.contratos.gov.co/consultas/detalleProceso.do?numConstancia=22-4-13069370&g-recaptcha-response=03AGdBq27QfFgk-yCMWVHGwyGLKGSmDQhhJ1nyfWF4YQIsqcRGtlEzYLsTR-SflH8a09MzBojFWUSblxn1c8hicIlVjorR6NZm7KFT72Hn8buwSsNv3nwaf_UTAE5L12J0yU9f8U6V3Q-XuYSAhncBeI5cvb1YYoNCOj8zRvf_Dt1JUe98AIZNZvmxjInqjWLltKfD410IjNGpULnNMi3QQLsNTaXMhw2MyCPd9j03alNKe3Ya567zUKcIhl1vKzYWWjKnk__YlgsFfjvaqfzK8em76X1wd1R2NNlxNO-ToplpSZHxs7EPUA0SriV6qUIAyMotgaAbtXZf5aoq9MeXhsnG0-fUQZI3z1dihvyYbz4N7mz3dUpXYyyGawaM2k8l0x8KWa0rq6v6P9Lgyt9njgAvzvcOnGlHsyPFz9S5HcxXfxXmTcUqycw-nLS1So42QVi0EszNt9SCTNoXdKOIJ071JhdBdwVRQw" target="_blank" title="Ver información SECOP 1"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Documento Invitación Pública - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/doc-invitacion-publica.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p11/doc-invitacion-publica.pdf" download="doc-invitacion-publica.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Estudios Previos - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/est-previos.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p11/est-previos.pdf" download="est-previos.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Análisis del Sector - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/analisis-sector.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p11/analisis-sector.pdf" download="analisis-sector.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Disponibilidad Presupuestal No. SGR 322 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/disp-presupuestal.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p11/disp-presupuestal.pdf" download="disp-presupuestal.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">CDP Regalías - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/CDP_REGALIAS.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p11/CDP_REGALIAS.pdf" download="CDP_REGALIAS.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #1 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato1.docx" download="formato1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #2 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato2.doc" download="formato2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #3 Experiencia - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato3-exp.xls" download="formato3-exp.xls" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #4 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato4.doc" download="formato4.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #5 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato5.doc" download="formato5.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #6 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato6.doc" download="formato6.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #7 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato7.docx" download="formato7.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #8 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato8.doc" download="formato8.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #9 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formato9.docx" download="formato9.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #1 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/anexo1.doc" download="anexo1.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #2 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/anexo2.pdf" download="anexo2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #3 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/anexo3.pdf" download="anexo3.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #4 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/anexo4.pdf" download="anexo4.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formulario #1 - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/formulario1.docx" download="formulario1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Cronograma - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/cronograma.pdf" download="cronograma.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Adenda #1. Modificación de la Forma de Pago - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/1adenda1.pdf" download="1adenda1.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Informe de Evaluación - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/evaluacion.pdf" download="evaluacion.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Aviso Veedurías Ciudadanas - Proceso IP-FC-06-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p11/3aviso_veedurias_ciudadanas.pdf" download="3aviso_veedurias_ciudadanas.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
</div>