import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-p010',
  templateUrl: './p010.component.html',
  styleUrls: ['./p010.component.scss']
})
export class P010Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
