import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  baseUrl: string = "";

  constructor(private http: HttpClient, private _configService: ConfigService) { 
    this.baseUrl = this._configService.getApiURI();
  }

  saveData(data: any) {
    let body = JSON.stringify({
      ProveedorNombre: data.ProveedorNombre,
      RepresentanteNombre: data.RepresentanteNombre,
      Identificacion: data.Identificacion,
      NombreIntegrantes: data.NombreIntegrantes,
      CorreoEletronico: data.CorreoElectronico,
      Telefono: data.Telefono,
      Fax: data.Fax,
      Direccion: data.Direccion
    });

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(this.baseUrl + "/BancoOferentesFaro/register", body, httpOptions);
  }

  savePqrs(data: any) {
    let body = JSON.stringify({
      Name: data.Name,
      LastName: data.LastName,
      DocumentType: data.DocumentType,
      Document: data.Document,
      Phone: data.Phone,
      Email: data.Email,
      Address: data.Address,
      PqrsType: data.PqrsType,
      PqrsDescription: data.PqrsDescription,
      Description: 'Ok'
    });

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(this.baseUrl + "/Pqrs", body, httpOptions);
  }

  uploadFile(file: File, pqrsId: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.put<any>(this.baseUrl + "/Pqrs/post-file/" + pqrsId, formData);
  }

}
