<div id="home">
    <div class='pageblock' id='fullscreen'>
        <div class='slider'>
            <div class='slide' id="sec">
                <div class='just_pattern'></div>
                <div class='slidecontent'>
                    <span class="headersur">Bienvenido</span>
                    <h1><span><span>Faro</span> del Catatumbo</span>
                    </h1>
                </div>
            </div>
            <div class='slide' id="thirth">
                <div class='just_pattern'></div>
                <div class='slidecontent'>
                    <span class="headersur">Bienvenido</span>
                    <h1><span><span>Faro</span> del Catatumbo</span>
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="container">
    <div class="card" id="proyectosTerminados">
        <h3>Proyectos Terminados</h3>
        <p class="count">0</p>
    </div>

    <div class="card" id="proyectosEjecucion">
        <h3>Proyectos en Ejecución</h3>
        <p class="count">0</p>
    </div>

    <div class="card" id="proyectosIniciar">
        <h3>Proyectos por Iniciar</h3>
        <p class="count">0</p>
    </div>

    <div class="card" id="poblacionBeneficiada">
        <h3>Municipios Beneficiados</h3>
        <p class="count">0</p>
    </div>
</div>