<div id="about">
    <br><br>
    <div class="container">
        <div class="sixteen columns">
            <div class="top-text">
                <h2 class="titulo-normal">Quienes somos</h2>
                <div class="subline"></div>
            </div>
        </div>
        <div class="four columns">
            <div class="sidebar">
                <div class="categories">
                    <a id="set1">¿Qué hacemos?</a>
                </div>
                <div class="categories">
                    <a id="set2">Linea de servicios</a>
                </div>
                <div class="categories">
                    <a id="set3">Reseña historica</a>
                </div>
                <div class="categories">
                    <a id="set4">Misión y Visión</a>
                </div>
                <div class="categories">
                    <a id="set5">Estructura Orgánica</a>
                </div>
                <div class="categories">
                    <a id="set6">Manual de Funciones</a>
                </div>
                <div class="categories">
                    <a id="set7">Mapa y Descripción de Procesos</a>
                </div>
                <div class="categories">
                    <a id="set8">Directorio Institucional</a>
                </div>
                <div class="categories">
                    <a id="set9">Directorio de Empleados y Contratistas</a>
                </div>
                <div class="categories">
                    <a id="set10">Directorio de Entidades</a>
                </div>
                <div class="categories">
                    <a id="set11">Aliados Estratégicos</a>
                </div>
                <div class="categories">
                    <a id="set12">Directorio de Agremiaciones o Asociaciones donde participe</a>
                </div>
                <div class="categories">
                    <a id="set13">Mecanismos para presentar PQRD</a>
                </div>
                <div class="categories">
                    <a id="set14">Calendario de actividades</a>
                </div>
                <div class="categories">
                    <a id="set15">Autoridades que los vigilen</a>
                </div>
                <div class="categories">
                    <a id="set16">Manual de Identidad Corporativa</a>
                </div>
            </div>
        </div>
        <div class="twelve columns">
            <div class="blog-post">
                <p>
                    E.I.C.E. FARO DEL CATATUMBO S.A.S. – Es una Empresa Industrial y Comercial del Estado descentralizada por servicios de orden departamental, con personería jurídica, patrimonio propio, autonomía administrativa, vinculada al despacho del Gobernador y constituida
                    por sociedad por acciones simplificada SAS, comprometida con el desarrollo socioeconómico de Norte de Santander.
                </p>
                <div id="1">
                    <p><span>¿Qué hacemos?</span></p>
                    <p>
                        Trabajamos en alianza con entidades públicas y/o privadas para ejecutar con agilidad, idoneidad profesional, transparencia, responsabilidad, compromiso, efectividad y calidad los recursos asociados a proyectos estratégicos de la región.
                    </p>
                    <p>
                        Siendo la primera empresa industrial y comercial del estado que se encargara de gestionar, promover e impulsar todas las actividades comerciales, industriales de servicios y consultoría relacionadas con el desarrollo de planes, programas y proyectos de
                        infraestructura, equipamiento social y comunitario, vivienda, desarrollo urbano, desarrollo territorial, gestión predial y gestión social entre otros, en el Departamento Norte de Santander, en cooperación, coordinación y concurrencia
                        con los municipios del departamento o sus entidades descentralizadas , mediante la ejecución de estrategias y acciones que promuevan y potencien la participación activa de los sectores público, privado y solidario en pro del desarrollo
                        y regional.
                    </p>
                </div>
                <div id="2">
                    <p><span>Linea de servicios</span></p>
                    <div class="flex">
                        <div class="flexsize">
                            <p class="paragraf-left">Gerencia de proyectos</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Revisión y ajuste de los proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Supervisión y seguimiento de la ejecución de contratos y proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Acompañamiento técnico a proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Control y manejo de inversiones de recursos para proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Elaboración de reglas de participación y lineamientos contractuales. </p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Realización de procesos de selección de oferentes.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>valuación jurídica, financiera, técnica y económica de ofertas.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Selección y aceptación de ofertas.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Celebración de contratos y/o convenios.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Tramite de desembolsos.</p>
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">Gestión de proyectos</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Evaluación y diagnóstico de los aspectos financieros, administrativos y legales requeridos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Análisis, diseño y determinación de esquemas y procedimientos de ejecución.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Apoyo administrativo, financiero y operativo.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Preparación, coordinación y ejecución de estrategias, procedimientos y cronogramas del proyecto.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Apoyo logístico al cliente en la implementación de las actividades contractuales.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Celebración de los acuerdos contractuales.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Gestión financiera de los recursos que el cliente destine para la ejecución del proyecto.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Implementación de esquemas de seguimiento y control de todas las actividades.</p>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flexsize">
                            <p class="paragraf-left">Estructuración de proyectos</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesoría financiera integral.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Estructuración financiera de proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Estructuración integral de proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Estudios de prefactibilidad y factibilidad.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Diseño de instrumentos financieros adecuados a cada proyecto.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Diseño de estrategias para la consecución de recursos financieros para empresas y proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Determinación de las condiciones de viabilidad de los proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Estructuración de empresas regionales de servicios.</p>
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">Evaluación de proyectos</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Apoyo en la definición de criterios y metodología para la evaluación eficiente de proyectos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Diseño de esquemas para la optimización de la asignación de recursos disponibles.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Evaluación de proyectos siguiendo los criterios y metodologías definidas.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Acompañamiento en la asesoría, seguimiento, acompañamiento, capacitación, formulación y avaluación en iniciativas de emprendimiento empresarial.</p>
                        </div>
                    </div>
                </div>
                <div id="3">
                    <p><span>Reseña historica</span></p>
                    <p>
                        La Empresa Industrial y Comercial del Estado denominada la EICE FARO DEL CATATUMBO nace el 21 de diciembre de 2020, mediante ordenanza 0021 por iniciativa de la honorable Asamblea Departamental en uso de las facultades previstas numeral 7 del articulo
                        300 de la Constitución Política, en la ley 489 de 1998. La misma está vinculada al Despacho del Gobernador, dotada de personería jurídica, patrimonio propio y autonomía administrativa, la cual fue constituida como Sociedad de Acciones
                        Simplificadas (SAS), del orden departamental, de naturaleza industrial y comercial, que se rige conforme a la ley, por las reglas del derecho privado, en concordancia con la definición prevista por el articulo 85 de la ley 489
                        de 1998 para este tipo de empresas.
                    </p>
                    <p>
                        La EICE FARO DEL CATATUMBO desarrolla dentro de su objeto social gestionar, promover e impulsar todas las actividades comerciales, industriales, de servicios y consultoría relacionadas con el desarrollo de planes y programas y proyectos de infraestructura,
                        equipamiento social y comunitario, vivienda, desarrollo urbano, desarrollo territorial, gestión social y comunitario, gestión predial y gestión social entre otros, en el Departamento de Norte de Santander, en cooperación, en cooperación,
                        coordinación y concurrencia con los municipios del departamento o sus entidades descentralizadas, mediante la ejecución de estrategias y acciones que promuevan y potencien la participación activa de los sectores público, privado
                        y solidario en pro del desarrollo territorial y regional; por lo tanto, el reto para la E.I.C.E Faro del Catatumbo es consolidarse como empresa líder en su sector con amplia proyección regional.
                    </p>
                </div>
                <div id="4">
                    <p><span>Misión</span></p>
                    <p>
                        Gestionar, promover e impulsar todas las actividades comerciales, industriales, de servicios y consultoria relacionadas con el desarrollo de planes, programas y proyectos de infraestructura, equipamiento social y comunitario, vivienda, desarrollo urbano,
                        desarrollo territorial, gestión predial y gestión social entre otros, en el Departamento Norte de Santander, en cooperación, coordinación y concurrencia con los municipios del departamento o sus entidades descentralizadas, mediante
                        la ejecución de estrategias y acciones que promuevan y potencien la participación activa de los sectores público, privado y solidario en pro del desarrollo territorial y regional.
                    </p>
                    <p><span>Visión</span></p>
                    <p>
                        La EICE FARO DEL CATATUMBO S.A.S, será para el año 2023, una empresa auto sostenible, líder y reconocida a nivel Departamental por promover e impulsar todas las actividades comerciales, industriales, de servicios y consultoria relacionadas con el desarrollo
                        de planes, programas y proyectos de infraestructura, equipamiento social y comunitario, vivienda, desarrollo urbano, desarrollo territorial, gestión predial y gestión social entre otros.
                    </p>
                </div>
                <div id="5">
                    <p><span>Estructura Orgánica</span></p>
                    <img style="width: 100%!important; padding: 20px;" src="./assets/images/organigrama.jpg" alt="organigrama">
                    <p class="paragraf-left">Estructura Orgánica EICE Faro del Catatumbo
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_009_estructura_organica.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_009_estructura_organica.pdf" download="estructura_organica.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
                <div id="6">
                    <p><span>Manual de Funciones</span></p>
                    <p>
                        En la actualidad La EICE FARO DEL CATATUMBO S.A.S, es la única entidad que presta los servicios para Gestionar, promover e impulsar todas las actividades comerciales, industriales, de servicios y consultoria relacionadas con el desarrollo de planes, programas
                        y proyectos de infraestructura, equipamiento social y comunitario, vivienda, desarrollo urbano, desarrollo territorial, gestión predial y gestión social entre otros, en el Departamento Norte de Santander.
                    </p>
                    <p>
                        Contamos un equipo humano interdisciplinario, altamente calificado y entrenado, trabajamos para mejorar la calidad de vida de nuestros clientes, prestando el siguiente servicio:
                    </p>
                    <img style="width: 35%!important; margin-left: 32.5%" src="./assets/images/funcionimagen.png" alt="funciones">
                    <h6 style="padding: 0 20px; text-align: center;">Desarrollo de proyectos de infraestructura, equipamiento social y comunitario</h6>
                    <p>
                        La EICE FARO DEL CATATUMBO S.A.S desarrollará todas las acciones y actividades que directa o indirectamente estén relacionadas con su objeto y en particular las siguientes:
                    </p>
                    <div class="flex">
                        <div class="flexsize">
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Promover, estructurar, desarrollar, gerenciar, ejecutar y evaluar planes, programas y proyectos que contribuyan a generar crecimiento económico y social sostenible
                                en el territorio del departamento.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Realizar las gestiones necesarias para garantizar la viabilidad financiera de la empresa “EICE FARO DEL CATATUMBO S.A.S”., y la de los proyectos que administra
                                o ejecuta.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Prestar asesoría y asistencia técnica a entidades públicas y privadas en materias relacionadas con proyectos de desarrollo.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Identificar, establecer la factibilidad y estructurar técnica, financiera, social y legalmente; planes, programas y proyectos de infraestructura, equipamiento
                                social y comunitario.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Participar en el desarrollo de planes, programas y proyectos de infraestructura y equipamiento, aportando recursos técnicos y financieros, reembolsables o no reembolsables.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Gerenciar directa o indirectamente el desarrollo de los proyectos relacionados con el objeto social.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Celebrar contratos y convenios con entidades públicas, privadas o de otra índole, con el fin de adelantar la estructuración, promoción y ejecución de proyectos
                                de infraestructura, equipamiento social y comunitario.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Emitir o garantizar la emisión de títulos que tengan como objeto reconocer el valor de salarios, materiales de construcción, terrenos, equipos y todos los demás
                                costos necesarios para la ejecución de proyectos que desarrolle en virtud de su objeto.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Emitir o garantizar la emisión de todo tipo de bonos, títulos valores, pagarés y demás instrumentos financieros que sean necesarios para la obtención de fondos
                                con destino a ejecución de proyectos.
                            </p>
                        </div>
                        <div class="flexsize">
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Solicitar y obtener créditos y recursos de cooperación, de entidades públicas y privadas, nacionales e internacionales, con el fin de desarrollar el objeto social
                                de la empresa.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Administrar recursos de terceros, bien sea de personas naturales o jurídicas, privadas o públicas, con destino a la promoción, fomento, financiación o construcción
                                de los proyectos relacionados con su objeto social.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Celebrar todos los convenios o contratos necesarios para el cumplimiento del objeto social y de las funciones de la empresa y en general celebrar o ejecutar los
                                actos complementarios o accesorios a los anteriores.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Participar en cualquier tipo de sociedades o asociaciones, consorcio o unión temporal, hacer parte, adquirir cuotas o partes de interés, según el caso, siempre
                                y cuando su objetivo social esté directa o indirectamente relacionado con el de la “EMPRESA
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Adquirir o enajenar títulos, derechos reales de bienes inmuebles necesarios para el cumplimiento de su objeto social.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Decretar la expropiación de inmuebles, conforme con lo establecido en las disposiciones legales y vigentes.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Recibir, administrar y habilitar cuando hubiere lugar a ello, inmuebles fiscales de la nación, del departamento y de los municipios o de sus entidades descentralizadas,
                                que puedan ser destinados al desarrollo del objeto social.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Prestar servicios de asesorías en el área de su objeto y cobrar por los mismos.
                            </p>
                            <p>
                                <i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Las demás que le sean asignadas por la Junta Directiva, de conformidad con las disposiciones legales, los estatutos y el objeto social de la empresa.
                            </p>
                        </div>
                    </div>
                    <p>
                        Mantenemos una actitud proactiva frente al manejo de la calidad, gestionamos nuestros procesos Gerenciales, Operacionales y de Apoyo bajo principios de igualdad, moralidad, eficacia, economía e imparcialidad.
                    </p>
                    <p>
                        Desarrollamos actividades de auditoría y control interno orientados a evaluar el cumplimiento global de la organización, además de permitir una retroalimentación eficaz que nos permitan tomar acciones de mejoramiento continuo.
                    </p>
                    <p>
                        Las principales características de nuestro servicio son:
                    </p>
                    <img style="width: 100%!important;" src="./assets/images/servicios2.jpg" alt="funciones2">
                    <p>
                        Nuestro principal objetivo: aumentar la satisfacción de nuestros clientes, enmarcado en nuestra cultura de mejoramiento continuo.
                    </p>
                </div>
                <div id="7">
                    <p><span>Mapa y Descripción de Procesos</span></p>
                    <p class="paragraf-left">Procedimiento de Ingreso y Baja de Bienes de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_baja_bienes.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_baja_bienes.pdf" download="proceso_baja_bienes.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Certificación de Procedimiento de Controles Responsables de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_controles_resp.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_controles_resp.pdf" download="proceso_controles_resp.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento para la Programación y Ejecución de Presupuesto de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_prog_ejec_presupuesto.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_prog_ejec_presupuesto.pdf" download="proceso_prog_ejec_presupuesto.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento para la Aprobación de Viáticos de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_aprob_viaticos.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_aprob_viaticos.pptx" download="proceso_aprob_viaticos.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento para el cargue a SIA Observa de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_cargue_sia.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_cargue_sia.pptx" download="proceso_cargue_sia.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento para Constancias y Certificaciones de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_const_cert.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_const_cert.pptx" download="proceso_const_cert.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento para la Contratación de Obra de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_contrata_obra.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_contrata_obra.pptx" download="proceso_contrata_obra.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento Junta Directiva de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_junta_directiva.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_junta_directiva.pptx" download="proceso_junta_directiva.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento PAA de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_paa.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_paa.pptx" download="proceso_paa.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento Posesión Funcionarios de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_posesion_funcionarios.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_posesion_funcionarios.pptx" download="proceso_posesion_funcionarios.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento para realizar Acta de Seriedad de Garantía en Procesos Contractuales de Obra de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_seriedad_obra.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_seriedad_obra.pptx" download="proceso_seriedad_obra.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procedimiento Solicitud de CDP de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/procesos/proceso_solicitud_cdp.pptx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/procesos/proceso_solicitud_cdp.pptx" download="proceso_solicitud_cdp.pptx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
                <div id="8">
                    <p><span>Directorio Institucional</span></p>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_2.png" width="100%" height="auto" alt="Foto perfíl Victor Oliverio Peña Maldonado" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>VÍCTOR OLIVERIO PEÑA MALDONADO<br><small>GERENTE</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ARQUITECTO<br>UNIVERSIDAD NACIONAL DE COLOMBIA<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ESPECIALISTA EN DISEÑO DE INTERIORES<br>UNIVERSIDAD NACIONAL DE COLOMBIA<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Secretario Privado del Departamento - GOBERNACION NORTE DE SANTANDER</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Secretario de Infraestructura del Departamento - GOBERNACION NORTE DE SANTANDER</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Secretario de Planeación Departamental - GOBERNACION NORTE DE SANTANDER</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Gerente - CENTRAL DE TRANSPORTES</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Director - SISBEN</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Director (e) Planeación Municipal</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Alto Consejero para Planes, Programas y Proyectos Especiales</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_3.png" width="100%" height="auto" alt="Foto perfíl Jose Felix Cruz Contreras" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>JOSE FELIX CRUZ CONTRERAS<br><small>SECRETARIO GENERAL</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>ADMINISTRADOR DE EMPRESAS<br>UNIVERSIDAD FRANCISCO DE PAULA SANTANDER<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ESPECIALISTA EN GERENCIA SOCIAL<br>UNIVERSIDAD SIMÓN BOLÍVAR<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional - GOBERNACIÓN DE NORTE DE SANTANDER</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional - VEOLIA SA ESP</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional - ALCALDÍA DE CUCUTA</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_6.png" width="100%" height="auto" alt="Foto perfíl Liliana Fuentes Medina" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>LILIANA FUENTES MEDINA<br><small>DIRECTORA DE PLANEACIÓN, ESTRUCTURACIÓN Y EJECUCIÓN DE PROYECTOS</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>INGENIERA CIVIL<br>UNIVERSIDAD ANTONIO NARIÑO<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>ABOGADA<br>UNIVERSIDAD FRANCISCO DE PAULA SANTANDER<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Constructora de obras civiles en el sector público y privado.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesora de Territorial de vivienda Gratuita - Departamento de Prosperidad Social</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional de apoyo Banco de Proyectos - GOBERNACION NORTE DE SANTANDER</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesora de Regalías - GOBERNACION NORTE DE SANTANDER</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_7.png" width="100%" height="auto" alt="Foto perfíl Sandra Yajaira Benitez Jaimes" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>SANDRA YAJAIRA BENITEZ JAIMES<br><small>ASESORA ADMINISTRATIVA Y FINANCIERA</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ADMINISTRADORA DE EMPRESAS<br>UNIVERSIDAD ANTONIO NARIÑO<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>ESPECIALISTA EN GERENCIA DE PROYECTOS<br>UNIVERSIDAD UNIMINUTO<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesora Administrativa y Financiera - EICE FARO DEL CATATUMBO SAS</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Secretaria General - EMPRESA AP SYSTEMS</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Administradora General de Norte de Santander - Empresa Unión Temporal Universidades del Nororiente. Universidad de Pamplona y Francisco de Paula Santander 2017.</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_4.png" width="100%" height="auto" alt="Foto perfíl Enidela Castilla Molina" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>ENIDELA CASTILLA MOLINA<br><small>SECRETARIA DE GERENCIA</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>TÉCNICO EN TRABAJO SOCIAL<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>TÉCNICO LABORAL CONTABLE Y FINANCIERO<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ADMINISTRACIÓN PUBLICA<br>En curso</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Promotora SIAU - IMSALUD</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Auxiliar Administrativo - GOBERNACION NORTE DE SANTANDER</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_8.png" width="100%" height="auto" alt="Foto perfíl Edisabel Villan Rojas" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>EDISABEL VILLAN ROJAS<br><small>PROFESIONAL EN INGENIERÍA CIVIL</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>INGENIERIA CIVIL<br>UNIVERSIDAD SANTO TOMÁS<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Ingeniera Civil - ARGOS OCUPADOS.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asistencia en Ingeniera Civil, interventoría y superviciones - SECRETARÍA DE AGUA POTABLE Y SANEAMIENTO BASICO GOBERNACIÓN DE NORTE DE SANTANDER.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Supervición Técnica y administrativa en proyectos derivados del programa de Saneamiento Basico de Calidad para Todos - SECRETARÍA DE INFRAESTRUCTURA GOBERNACIÓN DE NORTE DE SANTANDER.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Ingeniera Civil - ARGOS OCUPADOS.</p>

                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_1.png" width="100%" height="auto" alt="Foto perfíl Claudia Yajaira Galvis Mendoza" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>CLAUDIA YAJAIRA GALVIS MENDOZA<br><small>PROFESIONAL AMBIENTAL Y/O SANITARIA</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ADMINISTRADOR AMBIENTAL Y DE LOS RECURSOS NATURALES<br>UNIVERSIDAD SANTO TOMÁS<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>ESPECIALISTA EN DERECHO DEL MEDIO AMBIENTE<br>UNIVERSIDAD JORGE TADEO LOZANO<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Autoridad Sanitaria - IDS NORTE DE SANTANDER.</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_5.png" width="100%" height="auto" alt="Foto perfíl Ludy Zulay Ardila" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>LUDY ZULAY ARDILA<br><small>ASESOR CONTABLE Y DE TESORERIA</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>CONTADORA PÚBLICA<br>UNIVERSIDAD DE PAMPLONA<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>ESPECIALISTA EN GERENCIA TRIBUTARIA<br>UNIVERSIDAD LIBRE<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Directora de la Oficina de Pagaduría - UNIVERSIDAD DE PAMPLONA</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Directora Financiera - CONTRALORÍA GENERAL DEL DEPARTAMENTO</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_10.png" width="100%" height="auto" alt="Foto perfíl Eduardo Antonio Orozco Colmenares" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>EDUARDO ANTONIO OROZCO COLMENARES<br><small>LÍDER DE CONTRATACIÓN</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ESPECIALISTA EN DERECHO ADMINISTRATIVO<br>UNIVERSIDAD LIBRE DE COLOMBIA<br>Graduado</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional Universitario - REGISTRADURÍA NACIONAL DEL ESTADO CIVIL.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesor Juridico Externo - GOBERNACIÓN DE NORTE DE SANTANDER</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesor Juridico Externo - UNIDAD ADMINISTRATIVA ESPECIAL DE GESTIÓN DE RESTITUCIÓN DE TIERRAS DESPOJADAS - NORTE DE SANTANDER.</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                    <div class="flex">
                        <div class="flexsize">
                            <img src="./assets/images/personal/faro_9.png" width="100%" height="auto" alt="Foto perfíl Carlos Arturo Ramos Mejía" />
                        </div>
                        <div class="flexsize">
                            <p class="paragraf-left">
                                <span>CARLOS ARTURO RAMOS MEJIA<br><small>ASESOR JURIDICO DE DESPACHO</small></span>
                            </p>
                            <p>Formación académica:</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesional<br>ABOGADO<br>UNIVERSIDAD LIBRE<br>Graduado</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>MAGISTER(C) EN DERECHO CONSTITUCIONAL<br>UNIVERSIDAD EXTERNADO DE COLOMBIA</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>ESPECIALISTA EN NEGOCIACIÓN<br>UNIVERSIDAD DE LOS ANDES</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>TECNÓLOGO EN ANALISIS Y DESARROLLO DE SISTEMAS DE INNOVACIÓN<br>SENA</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>profesional<br>DIPLOMADO EN "DISCOVERING THE FUTURE: BUSSINESS MODEL INNOVATION POST COVID-19 WORLD"<br>SDA BOCONNI SCHOOL OF MANAGEMENT - MILANO</p>
                        </div>
                    </div>
                    <p>Experiencia laboral:</p>
                    <div class="flex">
                        <div class="flexsize">
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Profesor de Cátedra del Plan de Estudio de Derecho - Universidad Francisco de Paula Santander.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Director Administrativo - Corporación Red Departamental de Defensores de Derechos Humanos.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesor Jurídico Externo - E.S.E Hospital Regional Sur Oriental.</p>
                            <p><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Asesor Jurídico Externo - E.S.E Hospital Juan Luis Londoño.</p>
                        </div>
                    </div>
                    <div class="line-separator"></div>
                </div>
                <div id="9">
                    <p><span>Directorio de Empleados y Contratistas</span></p>
                </div>
                <div id="10">
                    <p><span>Directorio de Entidades</span></p>
                </div>
                <div id="11">
                    <p><span>Aliados Estratégicos</span></p>
                    <p class="paragraf-left">Alcaldes Electos de Norte de Santander
                        <span class="der">
                            <a href="./assets/docs/alcaldes.xlsx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/alcaldes.xlsx" download="alcaldes.xlsx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Aliados Institucionales
                        <span class="der">
                            <a href="./assets/docs/aliados.xlsx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/aliados.xlsx" download="aliados.xlsx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
                <div id="12">
                    <p><span>Directorio de Agremiaciones o Asociaciones donde participe</span></p>
                </div>
                <div id="13">
                    <p><span>Mecanismos para presentar PQRD</span></p>
                </div>
                <div id="14">
                    <p><span>Calendario de actividades</span></p>
                </div>
                <div id="15">
                    <p><span>Autoridades que los vigilen</span></p>
                </div>
                <div id="16">
                    <p><span>Manual de Identidad Corporativa</span></p>
                    <p class="paragraf-left">Manual de Identidad Corporativa
                        <span class="der">
                            <a href="./assets/docs/manual_identidad.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/manual_identidad.pdf" download="manual_identidad.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>