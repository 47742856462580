<div id="contact">
    <br><br>
    <div class="container">
        <div class="sixteen columns">
            <div class="top-text">
                <h2 class="titulo-normal">PQRSDF</h2>
                <div class="subline"></div>
                <div class="top-subtext" style="color: #000;">Bienvenido al espacio de PQRSDF del EICE Faro del
                    Catatumbo SAS</div>
            </div>
        </div>
        <div class="clear"></div>
        <div class="six columns">
            <p><b>¡Para nosotros es muy importante contar contigo!</b></p>
            <br>
            <p>La EICE Faro del Catatumbo SAS te escucha, formula tus preguntas e inquietudes, quejas, reclamos o
                sugerencias, seleccione la opción que más se ajuste a tus necesidades y llena el siguiente formulario
            </p>
            <br>
            <p><b>Petición:</b> Derecho que tiene toda persona para solicitar ante las autoridades competentes por
                razones de interés general o particular para elevar solicitudes respetuosas de información y/o consulta
                y para obtener pronta resolución.</p>
            <br>
            <p><b>Queja:</b> Cualquier expresión verbal, escrita o en medio electrónica de insatisfacción con la
                conducta o la acción de los servidores públicos o de los particulares que llevan a cabo una función
                estatal y que requiere una respuesta.</p>
            <br>
            <p><b>Reclamo:</b> Expresión verbal, escrita o en medio electrónico, de insatisfacción referida a la
                prestación de un servicio o la deficiente atención de una autoridad pública por el incumplimiento de un
                derecho que ha sido perjudicado.</p>
            <br>
            <p><b>Denuncias:</b> Es la puesta en conocimiento ante una autoridad competente de una conducta posiblemente
                irregular; para que según el caso que se adelante la correspondiente investigación disciplinaria, penal
                y/o fiscal.</p>
            <br>
            <p><b>Felicitación:</b> Es la manifestación a una persona de la satisfacción que se experimenta con motivo
                de algún suceso favorable</p>
            <br>
            <p><b>Sugerencia:</b> Es la manifestación de una idea o propuesta para mejorar la atención al público o la
                gestión de la entidad.</p>
        </div>
        <div class="ten columns">
            <form name="ajax-form" *ngIf="forma" [formGroup]="forma">
                <label for="Name">NOMBRE *
                </label>
                <input name="Name" formControlName="Name" type="text" />

                <label for="LastName">APELLIDO *
                </label>
                <input name="LastName" formControlName="LastName" type="text" />

                <label for="Identificacion">TIPO DE DOCUMENTO *
                </label>
                <select name="DocumentType" formControlName="DocumentType">
                    <option value="">Seleccione</option>
                    <option value="CedulaCiudadania">Cédula de Ciudadanía</option>
                    <option value="CedulaExtranjeria">Cédula de Extranjería</option>
                    <option value="Nit">NIT</option>
                    <option value="Pasaporte">Pasaporte</option>
                    <option value="RegistroCivil">Regístro Civil</option>
                    <option value="TarjetaExtranjeria">Tarjeta de Extranjería</option>
                    <option value="TarjetaIdentidad">Tarjeta de Identidad</option>
                </select>

                <label for="Document">NÚMERO DE DOCUMENTO *
                </label>
                <input name="Document" formControlName="Document" type="text" />

                <label for="Phone">TELÉFONO *
                </label>
                <input name="Phone" formControlName="Phone" type="number" />

                <label for="Email">CORREO ELECTRONICO *
                </label>
                <input name="Email" formControlName="Email" type="text" />

                <label for="Address">DIRECCIÓN DE RESIDENCIA
                </label>
                <input name="Address" formControlName="Address" type="text" />

                <label for="PqrsType">TIPO DE PETICIÓN *
                </label>
                <select name="PqrsType" formControlName="PqrsType">
                    <option value="">Seleccione</option>
                    <option value="Peticion">Petición</option>
                    <option value="Queja">Queja</option>
                    <option value="Reclamo">Reclamo</option>
                    <option value="Sugerencia">Sugerencia</option>
                    <option value="Denuncia">Denuncia</option>
                    <option value="Felicitacion">Felicitación</option>
                </select>

                <label for="PqrsDescription">DESCRÍBA SU PETICIÓN *
                </label>
                <textarea name="PqrsDescription" formControlName="PqrsDescription" rows="3"></textarea>

                <div id="button-con"><button [disabled]="formSuccess === true" (click)="saveChanges()"
                        class="send_message" id="send">Enviar Información</button></div>
            </form>
            <br>
            <div *ngIf="formSuccess === true">
                <h3>Adjuntar un archivo a su solicitud</h3>
                <form name="upload-form" [formGroup]="uploadForm" (ngSubmit)="onFileSubmit()">
                    <label for="file">Adjunte su archivo aquí *</label>
                    <input type="file" id="file" formControlName="file" (change)="onFileChange($event)" required />

                    <div id="button-con">
                        <button type="submit" [disabled]="!uploadForm.valid" class="send_message" id="upload">Subir
                            Archivo</button>
                    </div>
                </form>
                <br>
                <div *ngIf="postedFile.length > 0" class="uploaded-files-section">
                    <h3>Archivos Cargados:</h3>
                    <table class="uploaded-files-table">
                      <thead>
                        <tr>
                          <th>Nombre del Archivo</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let file of postedFile">
                          <td>{{ file.name }}</td>
                          <td><a [href]="file.url" target="_blank" class="btn-view">Ver</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                <br>
                <div id="button-con">
                    <button (click)="finishForm()" class="send_message" id="finish">Finalizar Proceso</button>
                </div>
            </div>
        </div>
    </div>
</div>