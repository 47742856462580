import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AtencionCiudadanoComponent } from './pages/atencion-ciudadano/atencion-ciudadano.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { ConvocatoriasComponent } from './pages/convocatorias/convocatorias.component';
import { HomeComponent } from './pages/home/home.component';
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { TransparenciaComponent } from './pages/transparencia/transparencia.component';
import { BancoOferentesComponent } from './pages/convocatorias/banco-oferentes/banco-oferentes.component';
import { ProcesosComponent } from './pages/convocatorias/procesos/procesos.component';
import { PqrsComponent } from './pages/pqrs/pqrs.component';

const routes: Routes = [
  {
    path: 'inicio',
    component: HomeComponent,
  },
  {
    path: 'nosotros',
    component: QuienesSomosComponent,
  },
  {
    path: 'transparencia',
    component: TransparenciaComponent,
  },
  {
    path: 'procesos',
    component: ProcesosComponent,
  },
  {
    path: 'contacto',
    component: ContactoComponent,
  },
  {
    path: 'atencion-ciudadano',
    component: AtencionCiudadanoComponent,
  },
  {
    path: 'pqrs',
    component: PqrsComponent,
  },
  {
    path: 'convocatorias',
    component: ConvocatoriasComponent,
    children: [
        { path: 'banco-oferentes', component: BancoOferentesComponent },
        { path: '**', pathMatch: 'full', redirectTo: 'banco-oferentes' }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'inicio',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
      useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
