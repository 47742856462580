<nav style="position: fixed;z-index: 100000;" class="navbar navbar-expand-lg barra-superior-govco" aria-label="Barra superior">
    <a style="padding-top: 10px;" href="https://www.gov.co/" target="_blank" aria-label="Portal del Estado Colombiano - GOV.CO"></a>
  </nav>
<div id="menu-wrap" class="menu-back cbp-af-header cbp-af-header-shrink">
    <div class="container">
        <div class="seventeen columns">
            <div class="logo"></div>
            <ul class="slimmenu">
                <li>
                    <a routerLink="inicio">inicio</a>
                </li>
                <li>
                    <a routerLink="nosotros">nuestra empresa</a>
                </li>
                <li>
                    <a routerLink="transparencia">transparencia</a>
                </li>
                <li>
                    <a routerLink="atencion-ciudadano">atención y servicio a la ciudadanía</a>
                </li>
                <li>
                    <a routerLink="pqrs">participa</a>
                </li>
                <li>
                    <a routerLink="contacto">prensa</a>
                </li>
            </ul>
        </div>
    </div>
</div>