<div id="footer">
    <div class="container">
        <div class="six columns">
            <img style="width: 200px!important; height: auto!important;" src="../../../../../assets/images/logo.png" alt="logofaro">
        </div>
        <div class="ten columns">
            <p style="font-weight: 600; text-align: justify;"><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>www.eicefarodelcatatumbo.gov.co</p>
            <p style="font-weight: 600; text-align: justify;"><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Avenida 5 #13-82 Oficinas 204-205-206-207-208 y 210 Edificio Centro de Negocios Quinta Avenida</p>
            <p style="font-weight: 600; text-align: justify;"><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>Lunes a viernes de 8:00 a.m. – 11:30 m a 2:00 p.m. – 5:30 p.m.</p>
            <p style="font-weight: 600; text-align: justify;"><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>{{mail}}</p>
            <p style="font-weight: 600; text-align: justify;"><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>{{mail_judicial}}</p>
            <p style="font-weight: 600; text-align: justify;"><i class="icon-blog" style="margin-right: 5px;">&#xf138;</i>6075493338</p>
        </div>
        <div class="sixteen columns">
            <p>Copyright © {{date | date: 'YYYY'}}</p>
            <div class="social-footer">
                <ul class="footer-social">
                    <li class="icon-footer" style="padding-right: 10px;"><a href="#" title="Nuestro perfil de Twitter" style="font-size: 2rem;">&#xf099;</a></li>
                    <li class="icon-footer" style="padding-right: 10px; padding-left: 10px;"><a href="#" title="Nuestro perfil de Facebook" style="font-size: 2rem;">&#xf09a;</a></li>
                    <li class="icon-footer" style="padding-right: 10px; padding-left: 10px;"><a href="#" title="Nuestro pagina de Instragram" style="font-size: 2rem;">&#xf16d;</a></li>
                    <li class="icon-footer" style="padding-right: 10px; padding-left: 10px;"><a href="#" title="Nuestro canal de Youtube" style="font-size: 2rem;">&#xf16a;</a></li>
                    <li class="icon-footer" style="padding-left: 10px;"><a href="#" title="Nuestra ubicacion en Google Maps" style="font-size: 2rem;">&#xf041;</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>