import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from 'src/app/services/register.service';

declare var Swal: any;

@Component({
  selector: 'app-pqrs',
  templateUrl: './pqrs.component.html',
  styleUrls: ['./pqrs.component.scss']
})
export class PqrsComponent implements OnInit {

  forma: FormGroup;
  uploadForm: FormGroup;
  theFile: any;
  postedFile: any[] = [];

  formSuccess: boolean = false;

  constructor(private _registerService: RegisterService, private fb: FormBuilder) {
    this.uploadForm = this.fb.group({
      file: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.formInit();
  }

  formInit() {
    this.forma = new FormGroup({
      Id: new FormControl(0),
      Name: new FormControl(''),
      LastName: new FormControl(''),
      DocumentType: new FormControl(''),
      Document: new FormControl(''),
      Phone: new FormControl(''),
      Email: new FormControl(''),
      Address: new FormControl(''),
      PqrsType: new FormControl(''),
      PqrsDescription: new FormControl('')

    });
  }

  resetForm() {
    let empty = {
      Name: '',
      LastName: '',
      DocumentType: '',
      Document: '',
      Phone: '',
      Email: '',
      Address: '',
      PqrsType: '',
      PqrsDescription: '',
      Description: 'Ok'
    };

    this.forma.setValue(empty);
  }

  saveChanges() {
    if (
      this.forma.value.Name === "" ||
      this.forma.value.LastName === "" ||
      this.forma.value.DocumentType === "" ||
      this.forma.value.Document === "" ||
      this.forma.value.Phone === "" ||
      this.forma.value.Email === "" ||
      this.forma.value.PqrsType === "" ||
      this.forma.value.PqrsDescription === ""
    ) {
      Swal.fire({
        icon: "warning",
        title:
          "Debes completar los campos requeridos del formulario para continuar.",
        showConfirmButton: true,
      });
      return;
    }

    this._registerService.savePqrs(this.forma.value).subscribe(
      (result: any) => {
        if (result.status === true) {
          this.forma.controls['Id'].setValue(result.data.id);
          this.formSuccess = true;

          Swal.fire({
            icon: "success",
            title:
              "El registro se ha realizado exitosamente.",
            showConfirmButton: true,
          });

        } else {
          Swal.fire({
            icon: "warning",
            title:
              result.message,
            showConfirmButton: true,
          });
          return;
        }
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title:
            "El registro no se ha podido realizar. Por favor intente más tarde.",
          showConfirmButton: true,
        });
        return;
      }
    );
  }

  onFileChange(event: any) {
    if (this.formSuccess === false) {
      Swal.fire({
        icon: "warning",
        title:
          "Para subir un archivo primero debes diligenciar una solicitud.",
        showConfirmButton: true,
      });
      return;
    }

    this.theFile = event.target.files[0];
    
  }

  onFileSubmit() {
    if (this.formSuccess === false) {
      Swal.fire({
        icon: "warning",
        title:
          "Para subir un archivo primero debes diligenciar una solicitud.",
        showConfirmButton: true,
      });
      return;
    }

    if (this.uploadForm.valid) {
      this._registerService.uploadFile(this.theFile, this.forma.value.Id)
      .subscribe((result: any) => {
        if (result.status === true) {
          Swal.fire({
            icon: "success",
            title:
              "El archivo ha sido cargado exitosamente.",
            showConfirmButton: true,
          });

          this.postedFile.push(result.data);
          console.log(this.postedFile);
        }
      });
    }
  }

  finishForm() {
    if (this.formSuccess === false) {
      Swal.fire({
        icon: "warning",
        title:
          "Para finalizar este proceso primero debes registrar una solicitud.",
        showConfirmButton: true,
      });
      return;
    }

    Swal.fire({
      title: "¿Está seguro que desea finalizar el proceso?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Sí",
      denyButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Proceso finalizado exitosamente. Gracias por usar nuestros servicios.", "", "success");
        this.formSuccess = false;
        this.resetForm();
      }
    });
  }
}
