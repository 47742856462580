<div id="about">
    <br><br>
    <div class="container">
        <div class="sixteen columns">
            <div class="top-text">
                <h2 class="titulo-normal">Transparencia y acceso a la información</h2>
                <div class="subline"></div>
            </div>
        </div>
        <div class="four columns">
            <div class="sidebar">
                <h6 class="titulo-normal">Categorias</h6>
                <div class="categories">
                    <a id="gnorm">NORMATIVIDAD</a>
                </div>
                <div class="categories">
                    <a id="gcont">CONTRATACIÓN</a>
                </div>
                <div class="categories">
                    <a id="gplan">PLANEACIÓN, PRESUPUESTO E INFORMES</a>
                </div>
                <div class="categories">
                    <a id="gtram">TRAMITES</a>
                </div>
                <div class="categories">
                    <a id="gpart">PARTICIPA</a>
                </div>
                <div class="categories">
                    <a id="gdatos">DATOS ABIERTOS</a>
                </div>
                <div class="categories">
                    <a id="ginfo1">INFORMACIÓN ESPECÍFICA PARA GRUPOS DE INTERES</a>
                </div>
                <div class="categories">
                    <a id="goblig">OBLIGACIÓN DEL REPORTE DE INFORMACIÓN ESPECÍFICA POR PARTE DE LA ENTIDAD</a>
                </div>
                <div class="categories">
                    <a id="gotros">OTROS ENLACES DE INTERÉS</a>
                </div>
            </div>
        </div>
        <div class="twelve columns">
            <div class="blog-post">
                <div id="norm">
                    <p><span>NORMATIVIDAD</span></p>
                    <p class="paragraf-left">Normograma EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/normograma-faro.xlsx" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/normograma-faro.xlsx" download="normograma-faro.xlsx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Manual de Funciones y Competencias Laborales EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/manual_funciones.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/manual_funciones.pdf" download="manual_funciones.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Estructura Orgánica Faro del Catatumbo
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_009_estructura_organica.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_009_estructura_organica.pdf" download="estructura_organica.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Junta Directiva EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/junta_directiva_2024-2027.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/junta_directiva_2024-2027.pdf" download="junta_directiva_2024-2027.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo 001 de 30 de Abril de 2021 - Estatutos de la Empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdo-001-estatutos-eice-faro-catatumbo.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdo-001-estatutos-eice-faro-catatumbo.pdf" download="acuerdo-001-estatutos-eice-faro-catatumbo.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Modificación Acuerdo 001 de 30 de Abril de 2021 - Estatutos de la Empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdo-007-modificacion-estatutos-eice-faro-catatumbo.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdo-007-modificacion-estatutos-eice-faro-catatumbo.pdf" download="acuerdo-007-modificacion-estatutos-eice-faro-catatumbo.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Ordenanza 0021 del 21 de Diciembre de 2020 - Creación de la Empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/ordenanzacreacionempresa.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/ordenanzacreacionempresa.pdf" download="ordenanzacreacionempresa.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo No. 005 de 12 de mayo de 2022 - Política Institucional de Mejoramiento Normativo de la Empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_005_2022.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_005_2022.pdf" download="acuerdo_005_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
                <div id="cont">
                    <p><span>CONTRATACIÓN</span></p>
                    <p class="paragraf-left">Plan Anual de Adquisiciones 31-01-2024 - EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/planes/plan_adquisiciones_31-01-2024.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/planes/plan_adquisiciones_31-01-2024.pdf" download="plan_adquisiciones_31-01-2024.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Plan Anual de Adquisiciones 15-03-2024 - EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/planes/plan_adquisiciones_15-03-2024.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/planes/plan_adquisiciones_15-03-2024.pdf" download="plan_adquisiciones_15-03-2024.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo No. 020 de 2022 <br> Modificación Manual de Contratación EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdo-020-modificacion-manual-contratacion.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdo-020-modificacion-manual-contratacion.pdf" download="acuerdo-020-modificacion-manual-contratacion.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo No. 008 de 2022 <br> Manual de Contratación EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdo-008-2022-manual-contratacion.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdo-008-2022-manual-contratacion.pdf" download="acuerdo-008-2022-manual-contratacion.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo No. 018 de 31 de octubre de 2022 - Manual de Inducción y Reinducción de los empleados de la Empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_018_2022.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_018_2022.pdf" download="acuerdo_018_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 0001 del 09 de junio de 2021 - Estatuto de Contratación
                        <span class="der">
                            <a href="./assets/docs/resolucion001.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resolucion001.pdf" download="resolucion001.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Convocatorias
                        <span class="der">
                            <a routerLink="/convocatorias" title="Convocatorias"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Procesos
                        <span class="der">
                            <a routerLink="/procesos" title="Procesos"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                        </span>
                    </p>
                </div>
                <div id="plan">
                    <p><span>PLANEACIÓN, PRESUPUESTO E INFORMES</span></p>
                    <p class="paragraf-left">Acuerdo No. 024 de 2023 - Presupuesto de Rentas y Recursos de Capital y de gastos de Funcionamiento, Servicio de la Deuda e Inversión del EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/planes/acuerdo_024_2023.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/planes/acuerdo_024_2023.pdf" download="planes/acuerdo_024_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Ejecución Presupuestal de Gastos 30-06-2024
                        <span class="der">
                            <a href="./assets/docs/planes/ejecucion_presupuestal_30-06-2024.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/planes/ejecucion_presupuestal_30-06-2024.pdf" download="ejecucion_presupuestal_30-06-2024.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Estado de Situación Financiera Inicial
                        <span class="der">
                            <a href="./assets/docs/balanceinicial.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/balanceinicial.pdf" download="balanceinicial.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Plan Anual de Gestión del Talento Humano Vigencia 2023
                        <span class="der">
                            <a href="./assets/docs/plan_anual_talento_humano_2023.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/plan_anual_talento_humano_2023.pdf" download="plan_anual_talento_humano_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Plan de Acción Gerencial Vigencia 2022-2023
                        <span class="der">
                            <a href="./assets/docs/plan_accion_gerencial_2022_2023.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/plan_accion_gerencial_2022_2023.pdf" download="plan_accion_gerencial_2022_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolución No. 009 de 2022 - Adopción Plan Anual del Sistema General de Seguridad y Salud en el Trabajo
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_009_2022.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_009_2022.pdf" download="res_009_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Plan de Acción SST Faro del Catatumbo SAS Vigencia 2021
                        <span class="der">
                            <a href="./assets/docs/acuerdo-004-2021.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdo-004-2021.pdf" download="acuerdo-004-2021.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Autoevalucion y Plan de Trabajo Anual SST Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/Autoevalucion_y_Plan_de_Trabajo_Anual_SST_Faro_del_Catatumbo_SAS.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/Autoevalucion_y_Plan_de_Trabajo_Anual_SST_Faro_del_Catatumbo_SAS.pdf" download="Autoevalucion_y_Plan_de_Trabajo_Anual_SST_Faro_del_Catatumbo_SAS.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Informe Final Plan Bianual Gerencial 2022-2023
                        <span class="der">
                            <a href="./assets/docs/informe_plan_bianual_2023.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/informe_plan_bianual_2023.pdf" download="informe_plan_bianual_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Informe de Gestión EICE Faro del Catatumbo SAS Vigencia 2020-2023
                        <span class="der">
                            <a href="./assets/docs/informe_de_gestion_2023.pdf" target="_blank"  title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/informe_de_gestion_2023.pdf" download="informe_de_gestion_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 076 del 26 de junio de 2023 - Actualización Plan Institucional de Capaciación del EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_076_2023.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_076_2023.pdf" download="res_076_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 043 del 10 de mayo de 2023 - Adopción Política de Gestión de Talento Humano en la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_043_2023.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_043_2023.pdf" download="res_043_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 0024 del 31 de diciembre de 2021 - Adopción del Manual de Prevención del daño Antijurídico
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_0024_2021.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_0024_2021.pdf" download="res_0024_2021.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo No. 002 de 12 de mayo de 2022 - Adopción Plan Institucional de Capacitación de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_002_2022.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_002_2022.pdf" download="acuerdo_002_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
                <div id="tram">
                    <p><span>TRAMITES</span></p>
                </div>
                <div id="part">
                    <p><span>PARTICIPA</span></p>
                    <p class="paragraf-left">Acuerdo No. 003 de 12 de mayo de 2022 - Política Institucional de Atención al Ciudadano y Peticiones, Quejas y Reclamos de la empresa EICE Faro del Catatumbo SAS 
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_003_2022.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_003_2022.pdf" download="acuerdo_003_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo No. 024 de 30 de noviembre de 2022 - Política Institucional para la Prevención, Atención y Consumo de Sustancias Psicoactivas - SPA 
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_024_2022.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_024_2022.pdf" download="acuerdo_024_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
                <div id="datos">
                    <p><span>DATOS ABIERTOS</span></p>
                </div>
                <div id="info1">
                    <p><span>INFORMACIÓN ESPECÍFICA PARA GRUPOS DE INTERES</span></p>
                </div>
                <div id="oblig">
                    <p><span>OBLIGACIÓN DEL REPORTE DE INFORMACIÓN ESPECÍFICA POR PARTE DE LA ENTIDAD</span></p>
                </div>
                <div id="otros">
                    <p><span>OTROS ENLACES DE INTERÉS</span></p>
                    <p><span>ACTOS ADMINISTRATIVOS</span></p>
                    <p class="paragraf-left">Resolucion No. 128 del 12 de septiembre de 2024
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_128_2024.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_128_2024.pdf" download="res_128_2024.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 123 del 21 de agosto de 2024
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_123_2024.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_123_2024.pdf" download="res_123_2024.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 107 del 12 de julio de 2024
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_107_2024.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_107_2024.pdf" download="res_107_2024.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 061 del 18 de abril de 2024
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_061_2024.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_061_2024.pdf" download="res_061_2024.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 198 del 11 de diciembre de 2023
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_198_2023.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_198_2023.pdf" download="res_198_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 190 del 04 de diciembre de 2023
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_190_2023.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_190_2023.pdf" download="res_190_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 032 del 31 de marzo de 2023 - Política Anticorrupción de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_032_2023.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_032_2023.pdf" download="res_032_2023.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolución #020 del 08 de Abril de 2022
                        <span class="der">
                            <a href="./assets/docs/res020.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/res020.pdf" download="res020.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 011 del 22 de febrero de 2022 - Adopción del Modelo Estandar de Control Interno de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_011_2022.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_011_2022.pdf" download="res_011_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acuerdo No. 010 de 26 de julio de 2022 - Política de Gestión Documental de la empresa EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/acuerdos/acuerdo_010_2022.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/acuerdos/acuerdo_010_2022.pdf" download="acuerdo_010_2022.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Resolucion No. 0023 del 31 de diciembre de 2021 - Código de Integridad de los Servidores Públicos del EICE Faro del Catatumbo SAS
                        <span class="der">
                            <a href="./assets/docs/resoluciones/res_0023_2021.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
                            <a href="./assets/docs/resoluciones/res_0023_2021.pdf" download="res_0023_2021.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>