<div id="cont">
    <p style="font-weight: 900;"><span>Proceso IP-FC-07-2022</span></p>
    <p class="paragraf-left">Información SECOP I - Proceso IP-FC-07-2022
        <span class="der">
            <a href="https://www.contratos.gov.co/consultas/detalleProceso.do?numConstancia=22-4-13069660&g-recaptcha-response=03AGdBq24Ianh3WSLcCoYV7E4QfiZkvR9PGPbHeQ7BMl_5uaPbdJsOm8-l6CRImMSCJBf7wclwOMSk8yL1yUUfWQpIj_S7VD80CTgqINX5v3Ysgotd7PrRwj9Hxa1USg-0EqS_R3Ucgq_wcxYryAALXYMkpPlDhtDqOkgteQVmntWB-_--XvsAk2lDKsltrqXHtoXv3DOrQBzcxpVhCtPxXoXZ5ixHZ-GvL433fyWxOYoSFE26KqLlprjUBvhy_w0QOt9DIgIYFs0AeTdJwHeV2VmeoDoT5aZ-bp5fl7KKkb6Gxg_ickC_SsdwY-lvoYfc__M_WZzA-n15nm1ZcuomfNGL4nLCJb489XVrnIUM0JpGaqUTNER282QnU7Gz2DYTUs0FULfy8-40OL7L79oFuKcuDxTB3x5E09bnEUi7qMtu2JmPeRensUJyax63CvGPcIoc3KKX3HdArHMNHM9G3t-2-JkVrZb8vQ" target="_blank" title="Ver información SECOP 1"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Documento Invitación Pública - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/doc-invitacion-publica.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p12/doc-invitacion-publica.pdf" download="doc-invitacion-publica.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Estudios Previos - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/est-previos.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p12/est-previos.pdf" download="est-previos.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Análisis del Sector - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/analisis-sector.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p12/analisis-sector.pdf" download="analisis-sector.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Disponibilidad Presupuestal No. SGR 322 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/disp-presupuestal.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p12/disp-presupuestal.pdf" download="disp-presupuestal.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">CDP Regalías - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/CDP_REGALIAS.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p12/CDP_REGALIAS.pdf" download="CDP_REGALIAS.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #1 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato1.docx" download="formato1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #2 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato2.doc" download="formato2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #3 Experiencia - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato3-exp.xls" download="formato3-exp.xls" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #4 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato4.doc" download="formato4.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #5 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato5.doc" download="formato5.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #6 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato6.doc" download="formato6.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #7 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato7.docx" download="formato7.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #8 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato8.doc" download="formato8.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #9 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formato9.docx" download="formato9.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #1 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/anexo1.doc" download="anexo1.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #2 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/anexo2.pdf" download="anexo2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #3 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/anexo3.pdf" download="anexo3.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #4 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/anexo4.pdf" download="anexo4.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formulario #1 - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/formulario1.docx" download="formulario1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Cronograma - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/cronograma.pdf" download="cronograma.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Adenda #1. Modificación de la Forma de Pago - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/1adenda1.pdf" download="1adenda1.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Informe de Evaluación - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/evaluacion.pdf" download="evaluacion.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Aviso Veedurías Ciudadanas - Proceso IP-FC-07-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p12/3aviso_veedurias_ciudadanas.pdf" download="3aviso_veedurias_ciudadanas.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
</div>