import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date = new Date();
  mail: string = "eicefarodelcatatumbosas@nortedesantander.gov.co";
  mail_judicial: string = "notificacionesjudiciales_eicefaro@nortedesantander.gov.co";
  constructor() { }

  ngOnInit(): void {
  }

}
