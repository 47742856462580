<div id="footer">
    <div class="container">
        <div class="sixteen columns">
            <h6>Nuestros aliados</h6>
            <div style="display: flex; justify-content: center; padding: 10px 0;">
                <span>
                    <img style="margin-right: 10px; width: 100%!important; height: auto!important;" src="./../../../../assets/images/logogober.png" alt="logo" />
                </span>
                <!-- <span>
                    <img style="margin-left: 10px;"> 
                </span> -->
            </div>
        </div>
    </div>
</div>