import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-p012',
  templateUrl: './p012.component.html',
  styleUrls: ['./p012.component.scss']
})
export class P012Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
