<div id="cont">
    <p style="font-weight: 900;"><span>Proceso IP-FC-05-2022</span></p>
    <p class="paragraf-left">Información SECOP I - Proceso IP-FC-05-2022
        <span class="der">
            <a href="https://www.contratos.gov.co/consultas/detalleProceso.do?numConstancia=22-4-13066665&g-recaptcha-response=03AGdBq25qBpwKiM72mmTnzffE4DbBbNY7RGm-xUxJxRKh82D6XakpfGjCAHxje61-XXFE04kRrsSKlm2ZQ0QM9_2bEW3AhnvYUaKftSDiqK4yT_bXKmin6E0a1DK_gZhhILtvH6kwyY1m8izBWlpfZjHviYXLy4tYPxPgRu5neoGd9uw4hBPbKtSRwbaNwKmhf3LUhzNu-rfSQmlaEKVhsy4qmG01y224TIsI3lKN0nfsc_EVmHBOIIg4VMSEdFRbq8KhcC8PkqGZlb9zgxCgV6sT126fnpfqSdoooHVieFdRPIh04Z1AXfqiY9308TD8tJ5q-eYWKkZnM664BiG7VRcvtKgzNRpZ88QTQWX1L3GysX9d-cJJyf0gxmZs84RbIpomogyPRJGtZGtPNE31LHl8L7MOzmXwkEJqyNh1HmyBLiX9ogv7pXFueVJvFy2SdD9XZndtvH2M3x7PxjpHjBoIjDCLP8j9iA" target="_blank" title="Ver información SECOP 1"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Documento Invitación Pública - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/doc-invitacion-publica.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p10/doc-invitacion-publica.pdf" download="doc-invitacion-publica.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Estudios Previos - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/est-previos.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p10/est-previos.pdf" download="est-previos.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Análisis del Sector - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/analisis-sector.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p10/analisis-sector.pdf" download="analisis-sector.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Disponibilidad Presupuestal No. SGR 322 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/disp-presupuestal.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p10/disp-presupuestal.pdf" download="disp-presupuestal.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">CDP Regalías - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/CDP_REGALIAS.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p10/CDP_REGALIAS.pdf" download="CDP_REGALIAS.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #1 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato1.docx" download="formato1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #2 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato2.doc" download="formato2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #3 Experiencia - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato3-exp.xls" download="formato3-exp.xls" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #4 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato4.doc" download="formato4.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #5 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato5.doc" download="formato5.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #6 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato6.doc" download="formato6.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #7 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato7.docx" download="formato7.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #8 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato8.doc" download="formato8.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #9 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formato9.docx" download="formato9.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #1 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/anexo1.doc" download="anexo1.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #2 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/anexo2.pdf" download="anexo2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #3 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/anexo3.pdf" download="anexo3.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #4 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/anexo4.pdf" download="anexo4.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formulario #1 - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/formulario1.docx" download="formulario1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Adenda #1. Modificación de la Forma de Pago - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/1adenda1.pdf" download="1adenda1.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Informe de Evaluación - Proceso IP-FC-05-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p10/evaluacion.pdf" download="evaluacion.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
</div>