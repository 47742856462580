import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-transparencia',
  templateUrl: './transparencia.component.html',
  styleUrls: ['./transparencia.component.scss'],
})
export class TransparenciaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    $(document).ready(this.setView());
  }

  setView(): any {
    $('#norm').show();
    $('#cont').hide();
    $('#plan').hide();
    $('#tram').hide();
    $('#part').hide();
    $('#datos').hide();
    $('#info1').hide();
    $('#oblig').hide();
    $('#otros').hide();


    $('#gnorm').click(function () {
      $('#norm').show();
      $('#cont').hide();
      $('#plan').hide();
      $('#tram').hide();
      $('#part').hide();
      $('#datos').hide();
      $('#info1').hide();
      $('#oblig').hide();
      $('#otros').hide();
    });
    $('#gcont').click(function () {
      $('#norm').hide();
      $('#cont').show();
      $('#plan').hide();
      $('#tram').hide();
      $('#part').hide();
      $('#datos').hide();
      $('#info1').hide();
      $('#oblig').hide();
      $('#otros').hide();
    });
    $('#gplan').click(function () {
      $('#norm').hide();
      $('#cont').hide();
      $('#plan').show();
      $('#tram').hide();
      $('#part').hide();
      $('#datos').hide();
      $('#info1').hide();
      $('#oblig').hide();
      $('#otros').hide();
    });
    $('#gtram').click(function () {
      $('#norm').hide();
      $('#cont').hide();
      $('#plan').hide();
      $('#tram').show();
      $('#part').hide();
      $('#datos').hide();
      $('#info1').hide();
      $('#oblig').hide();
      $('#otros').hide();
    });
    $('#gpart').click(function () {
      $('#norm').hide();
      $('#cont').hide();
      $('#plan').hide();
      $('#tram').hide();
      $('#part').show();
      $('#datos').hide();
      $('#info1').hide();
      $('#oblig').hide();
      $('#otros').hide();
    });
    $('#gdatos').click(function () {
      $('#norm').hide();
      $('#cont').hide();
      $('#plan').hide();
      $('#tram').hide();
      $('#part').hide();
      $('#datos').show();
      $('#info1').hide();
      $('#oblig').hide();
      $('#otros').hide();
    });
    $('#ginfo1').click(function () {
      $('#norm').hide();
      $('#cont').hide();
      $('#plan').hide();
      $('#tram').hide();
      $('#part').hide();
      $('#datos').hide();
      $('#info1').show();
      $('#oblig').hide();
      $('#otros').hide();
    });
    $('#goblig').click(function () {
      $('#norm').hide();
      $('#cont').hide();
      $('#plan').hide();
      $('#tram').hide();
      $('#part').hide();
      $('#datos').hide();
      $('#info1').hide();
      $('#oblig').show();
      $('#otros').hide();
    });
    $('#gotros').click(function () {
      $('#norm').hide();
      $('#cont').hide();
      $('#plan').hide();
      $('#tram').hide();
      $('#part').hide();
      $('#datos').hide();
      $('#info1').hide();
      $('#oblig').hide();
      $('#otros').show();
    });

    


    $('#gpol').click(function () {
      $('#pol').show();
      $('#planes').hide();
      $('#man').hide();
      $('#gest').hide();
    });
    $('#gplanes').click(function () {
      $('#pol').hide();
      $('#planes').show();
      $('#man').hide();
      $('#gest').hide();
    });
    $('#gman').click(function () {
      $('#pol').hide();
      $('#planes').hide();
      $('#man').show();
      $('#gest').hide();
    });
    $('#ggest').click(function () {
      $('#pol').hide();
      $('#planes').hide();
      $('#man').hide();
      $('#gest').show();
    });
  }
}
