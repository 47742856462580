import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lineas-servicio',
  templateUrl: './lineas-servicio.component.html',
  styleUrls: ['./lineas-servicio.component.scss']
})
export class LineasServicioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
