<div id="cont">
    <p style="font-weight: 900;"><span>Proceso IP-FC-08-2022</span></p>
    <p class="paragraf-left">Información SECOP I - Proceso IP-FC-08-2022
        <span class="der">
            <a href="https://www.contratos.gov.co/consultas/detalleProceso.do?numConstancia=22-4-13072789&g-recaptcha-response=03AGdBq25G8qANWn6eJ7E59_lKz5wbo0VDhPcUCT9Qjkql919oCkOV0yYar_WSdjqSqy2bfGIHlPguyCx5kOwH-g2TQi3H83xqb3g9ubybhDxyFrpnS4MuaDgUJYRXhMg09RF4MxQQlZZ6yiqwU8DRk0aws372GAJKcwn7YquuN29wW6-XmUt8zvdXALNYMXxDKMYuG_3kd4w1ymCZgfJDzAw3wlTp_5oqr4jdrWYNIJCnZseISasSBFm-QalOXTCjcxCTz-ovSKbLKUGvYQ_EPgBTS0ayzUb9hAAmwyfgT30ms5JEcLHRbBKfpU5I1lq7pBJviD8oiAQZC8xNucIeVx-1bQY28FL8D_Sc9AFw47xU_bmfIpbB7TKJ6aXWeGUsJi9B2X23FW1MXvkavlZP5LgjSoPXDer9nX_3zAkDXvlkwSmvsOUryTBvDSgb5GN-KG63G50cePJGRnT_Zbqyi3or074O5q-3Mw" target="_blank" title="Ver información SECOP 1"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Documento Invitación Pública - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/doc-invitacion-publica.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p13/doc-invitacion-publica.pdf" download="doc-invitacion-publica.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Estudios Previos - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/est-previos.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p13/est-previos.pdf" download="est-previos.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Análisis del Sector - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/analisis-sector.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p13/analisis-sector.pdf" download="analisis-sector.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Disponibilidad Presupuestal No. SGR 322 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/disp-presupuestal.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p13/disp-presupuestal.pdf" download="disp-presupuestal.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">CDP Regalías - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/CDP_REGALIAS.pdf" target="_blank" title="Ver documento"><i class="icon-blog" style="margin-right: 10px;">&#xf06e;</i></a>
            <a href="./../../../../../assets/documents/procesos/p13/CDP_REGALIAS.pdf" download="CDP_REGALIAS.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #1 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato1.docx" download="formato1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #2 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato2.doc" download="formato2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #3 Experiencia - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato3-exp.xls" download="formato3-exp.xls" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #4 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato4.doc" download="formato4.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #5 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato5.doc" download="formato5.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #6 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato6.doc" download="formato6.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #7 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato7.docx" download="formato7.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #8 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato8.doc" download="formato8.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formato #9 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formato9.docx" download="formato9.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #1 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/anexo1.doc" download="anexo1.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #2 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/anexo2.pdf" download="anexo2.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Anexo #3 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/anexo3.pdf" download="anexo3.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Formulario #1 - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/formulario1.docx" download="formulario1.docx" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Cronograma - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/cronograma.pdf" download="cronograma.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
    <p class="paragraf-left">Aviso Veedurías Ciudadanas - Proceso IP-FC-08-2022
        <span class="der">
            <a href="./../../../../../assets/documents/procesos/p13/3aviso_veedurias_ciudadanas.pdf" download="3aviso_veedurias_ciudadanas.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
        </span>
    </p>
</div>