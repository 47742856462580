<div id="about">
    <div class="container">
        <div class="sixteen columns">
            <div class="top-text">
                <h2 class="titulo-normal"> BANCO DE OFERENTES PARA LA MODALIDAD DE CONTRATACIÓN DIRECTA DE LA EMPRESA INDUSTRIAL Y COMERCIAL DEL ESTADO FARO DEL CATATUMBO S.A.S</h2>
                <div class="subline"></div>
            </div>
        </div>
        <div class="four columns">
            <div class="sidebar">
                <h6 class="titulo-normal">Categorias</h6>
                <div class="categories">
                    <a id="gcont">INFORMACIÓN</a>
                </div>
                <div class="categories">
                    <a id="gcon">REGISTRO</a>
                </div>
                <div class="categories">
                    <a id="gesc">DOCUMENTOS</a>
                </div>
            </div>
        </div>
        <div class="twelve columns">
            <div class="blog-post">
                <div id="cont">
                    <p><span>INFORMACIÓN DE LA CONVOCATORIA</span></p>
                    <p class="paragraf-left">Motivación de la Convocatoria
                        <span class="der">
                            <a href="./assets/documents/1_MOTIVACION_DE_LA_CONVOCATORIA.pdf" download="1_MOTIVACION_DE_LA_CONVOCATORIA.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Aviso de Convocatoria Pública
                        <span class="der">
                            <a href="./assets/documents/2_AVISO_CONVOCATORIA.pdf" download="2_AVISO_CONVOCATORIA.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Lineamientos de la Convocatoria
                        <span class="der">
                            <a href="./assets/documents/3_LINEAMIENTOS_DE_CALIFICACION_CONVOCATORIA_BANCO_DE_OFERENTES.pdf" download="3_LINEAMIENTOS_DE_CALIFICACION_CONVOCATORIA_BANCO_DE_OFERENTES.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Anexo I. Modificatorio de la Convocatoria
                        <span class="der">
                            <a href="./assets/documents/4_ANEXO_I_MODIFICATORIO.pdf" download="4_ANEXO_I_MODIFICATORIO.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Anexo II. Modificatorio 2 de la Convocatoria
                        <span class="der">
                            <a href="./assets/documents/5_ANEXO_II_MODIFICATORIO_2.pdf" download="5_ANEXO_II_MODIFICATORIO_2.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Anexo III. Modificatorio 3 de la Convocatoria
                        <span class="der">
                            <a href="./assets/documents/6_ANEXO_III_MODIFICATORIO_3.pdf" download="6_ANEXO_III_MODIFICATORIO_3.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Anexo IV. Modificatorio 4 de la Convocatoria
                        <span class="der">
                            <a href="./assets/documents/7_ANEXO_IV_MODIFICATORIO_4.pdf" download="7_ANEXO_IV_MODIFICATORIO_4.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Circular 002. Modificación Horario de Trabajo y Atención de Usuarios
                        <span class="der">
                            <a href="./assets/documents/8_CIRCULAR-002.pdf" download="8_CIRCULAR-002.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Acta 001. Comité de Recomendaciones
                        <span class="der">
                            <a href="./assets/documents/9_ACTA_001_COMITE_RECOMENDACIONES.pdf" download="9_ACTA_001_COMITE_RECOMENDACIONES.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Anexo VI. Modificatorio 5 de la Convocatoria
                        <span class="der">
                            <a href="./assets/documents/10_ANEXO_VI .pdf" download="10_ANEXO_VI.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Listado de Proveedores Definitivo
                        <span class="der">
                            <a href="./assets/documents/11_LISTA_PROVEEDORES_DEFINITIVO.pdf" download="11_LISTA_PROVEEDORES_DEFINITIVO.pdf" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
                <div id="con">
                    <p><span>REGISTRO EN LA CONVOCATORIA</span></p>
                    <div style="padding: 20px;">
                        <p>Para formalizar el Registro en el Banco de Oferentes para la Modalidad de Contratación Directa de la Empresa Industrial y Comercial del Estado Faro del Catatumbo S.A.S, debe diligenciar el formulario "Matriz 1. Formulario de registro
                            del Banco Público de Proveedores para la Contratación Directa".</p>
                        <br>
                        <form name="ajax-form" *ngIf="forma" [formGroup]="forma">
                            <label for="ProveedorNombre">NOMBRE DEL PROVEEDOR PERSONA NATURAL, JURÍDICA O CONTRACTUAL (CONSORCIO O UNIÓN TEMPORAL) * 
                            </label>
                            <input name="ProveedorNombre" formControlName="ProveedorNombre" type="text" />

                            <label for="RepresentanteNombre">NOMBRE DEL REPRESENTANTE LEGAL (APLICA PARA PERSONA JURÍDICA, CONSORCIO O UNIÓN TEMPORAL) * 
                            </label>
                            <input name="RepresentanteNombre" formControlName="RepresentanteNombre" type="text" />

                            <label for="Identificacion">IDENTIFICACIÓN (NIT O CÉDULA DE CIUDADANÍA) * 
                            </label>
                            <input name="Identificacion" formControlName="Identificacion" type="text" />

                            <label for="NombreIntegrantes">NOMBRE E IDENTIFICACIÓN DE LOS INTEGRANTES DEL CONSORCIO O UNIÓN TEMPORAL * 
                            </label>
                            <input name="NombreIntegrantes" formControlName="NombreIntegrantes" type="text" />

                            <label for="CorreoElectronico">CORREO ELECTRÓNICO * 
                            </label>
                            <input name="CorreoElectronico" formControlName="CorreoElectronico" type="text" />

                            <label for="Telefono">TELÉFONO FIJO - CELULAR * 
                            </label>
                            <input name="Telefono" formControlName="Telefono" type="text" />

                            <label for="Fax">FAX
                            </label>
                            <input name="Fax" formControlName="Fax" type="text" />

                            <label for="Direccion">DIRECCIÓN DE CORRESPONDENCIA * 
                            </label>
                            <input name="Direccion" formControlName="Direccion" type="text" />

                            <div id="button-con"><button (click)="saveChanges()" class="send_message" id="send">Enviar Información</button></div>
                        </form>
                    </div>
                </div>
                <div id="esc">
                    <p><span>DOCUMENTOS DE LA CONVOCATORIA</span></p>
                    <p class="paragraf-left">Matriz 1. Formulario de registro del Banco Público de Proveedores para la Contratación Directa
                        <span class="der">
                            <a href="./assets/documents/Matriz_1_Formulario_de_Registro_del_Banco_Publico_de_Proovedores_para_la_contratacion_directa.doc" download="Matriz_1_Formulario_de_Registro_del_Banco_Publico_de_Proovedores_para_la_contratacion_directa.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 2. Formulario de Conformación de Interesado Plural
                        <span class="der">
                            <a href="./assets/documents/Matriz_2_Formulario_de_conformacion_de_interesado_plural.doc" download="Matriz_2_Formulario_de_conformacion_de_interesado_plural.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 3. Formulario de capacidad financiera y organizacional para contratar
                        <span class="der">
                            <a href="./assets/documents/Matriz_3_Formulario_de_capacidad_financiera_y_organizacional_para_contratar.doc" download="Matriz_3_Formulario_de_capacidad_financiera_y_organizacional_para_contratar.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 4. Formulario de capacidad financiera y organizacional para contratar personas extranjeras sin domicilio en Colombia
                        <span class="der">
                            <a href="./assets/documents/Matriz_4_Formulario_de_capacidad_financiera_y_organizacional_para_contratar_personas_extranjeras_sin_domicilio_en_colombia.doc" download="Matriz_4_Formulario_de_capacidad_financiera_y_organizacional_para_contratar_personas_extranjeras_sin_domicilio_en_colombia.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 5. Formulario de capacidad legal personas extranjeras sin domicilio en Colombia
                        <span class="der">
                            <a href="./assets/documents/Matriz_5_Formulario_de_capacidad_legal_personas_extranjeras_sin_domicilio_en_colombia.doc" download="Matriz_5_Formulario_de_capacidad_legal_personas_extranjeras_sin_domicilio_en_colombia.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 6. Formulario de capacidad legal para contratar
                        <span class="der">
                            <a href="./assets/documents/Matriz_6_Formulario_de_capacidad_legal_para_contratar.doc" download="Matriz_6_Formulario_de_capacidad_legal_para_contratar.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 7. Formulario de idoneidad y experiencia en el sector
                        <span class="der">
                            <a href="./assets/documents/Matriz_7_Formulario_de_idoneidad_y_experiencia_en_el_sector.xls" download="Matriz_7_Formulario_de_idoneidad_y_experiencia_en_el_sector.xls" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 7 V2. Formulario de idoneidad y experiencia en el sector
                        <span class="der">
                            <a href="./assets/documents/Matriz_7_V2_Formulario_de_idoneidad_y_experiencia_en_el_sector.xls" download="Matriz_7_V2_Formulario_de_idoneidad_y_experiencia_en_el_sector.xls" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 7A. Formulario de idoneidad y experiencia en el sector
                        <span class="der">
                            <a href="./assets/documents/Matriz_7A_Formulario_de_idoneidad_y_experiencia_en_el_sector.xls" download="Matriz_7A_Formulario_de_idoneidad_y_experiencia_en_el_sector.xls" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 8. Formulario de capacidad Residual personas nacionales o extranjeras sin domicilio en Colombia
                        <span class="der">
                            <a href="./assets/documents/Matriz_8_Formulario_capacidad_residual_personas_nacionales_o_extranjeras_sin_domicilio_en_Colombia.doc" download="Matriz_8_Formulario_capacidad_residual_personas_nacionales_o_extranjeras_sin_domicilio_en_Colombia.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 9. Formulario de pago de Seguridad Social y aportes legales
                        <span class="der">
                            <a href="./assets/documents/Matriz_9_Formulario_de_pago_de_seguridad_social_y_aportes_legales.doc" download="Matriz_9_Formulario_de_pago_de_seguridad_social_y_aportes_legales.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 10. Formulario vinculación de personas con discapacidad
                        <span class="der">
                            <a href="./assets/documents/Matriz_10_Formulario_vinculacion_de_personas_con_discapacidad.doc" download="Matriz_10_Formulario_vinculacion_de_personas_con_discapacidad.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 11. Formulario de inhabilidades e incompatibilidades
                        <span class="der">
                            <a href="./assets/documents/Matriz_11_Formulario_de_inhabilidades_e_incompatibilidades.doc" download="Matriz_11_Formulario_de_inhabilidades_e_incompatibilidades.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 12. Formulario de declaración de origen y destinación de fondos
                        <span class="der">
                            <a href="./assets/documents/Matriz_12_Formulario_de_declaracion_de_origen_y_destinacion_de_fondos.doc" download="Matriz_12_Formulario_de_declaracion_de_origen_y_destinacion_de_fondos.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 13. Formulario compromiso anticorrupción
                        <span class="der">
                            <a href="./assets/documents/Matriz_13_Formulario_compromiso_anticorrupcion.doc" download="Matriz_13_Formulario_compromiso_anticorrupcion.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                    <p class="paragraf-left">Matriz 14. Formulario Consulta de Registro de obras inconclusas
                        <span class="der">
                            <a href="./assets/documents/Matriz_14_Formulario_Consulta_de_Registro_de_obras_inconclusas.doc" download="Matriz_14_Formulario_Consulta_de_Registro_de_obras_inconclusas.doc" title="Descargar documento"><i class="icon-blog">&#xf019;</i></a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>