<div id="about">
    <br><br>
    <div class="container">
        <div class="sixteen columns">
            <div class="top-text">
                <h2 class="titulo-normal">Atencion al ciudadano</h2>
                <div class="subline"></div>
            </div>
        </div>
        <div class="four columns">
            <div class="sidebar">
                <h6 class="titulo-normal">Categorias</h6>
                <div class="categories">
                    <a id="gdat">DATOS ABIERTOS</a>
                </div>
                <div class="categories">
                    <a id="gcon">CONTACTO</a>
                </div>
                <div class="categories">
                    <a id="gven">VENTANILLA ÚNICA DE ATENCIÓN AL CIUDADANO</a>
                </div>
                <div class="categories">
                    <a id="gmec">MECANISMOS DE CONTACTO</a>
                </div>
            </div>
        </div>
        <div class="twelve columns">
            <div class="blog-post">
                <div id="dat">
                    <p><span>DATOS ABIERTOS</span></p>
                    <p>Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac.</p>
                    <p class="paragraf-left">Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis
                        in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis
                        in, rhoncus ac.</p>
                    <p><strong class="caps">N</strong>ulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem
                        pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus
                        ac.
                    </p>
                </div>
                <div id="con">
                    <p><span>CONTACTO</span></p>
                    <p>Direccion</p>
                    <p class="paragraf-left">Avenida 5 #13-82 Oficinas 204-205-206-207-208 y 210 Edificio Centro de Negocios Quinta Avenida</p>
                    <p>Telefono</p>
                    <p class="paragraf-left">6075493338</p>
                    <p>Correo</p>
                    <p class="paragraf-left">{{mail}}</p>
                    <p>Correo Notificaciones Judiciales</p>
                    <p class="paragraf-left">{{mail_judicial}}</p>
                </div>
                <div id="ven">
                    <p><span>VENTANILLA ÚNICA DE ATENCIÓN AL CIUDADANO</span></p>
                    <p>Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac.</p>
                    <p class="paragraf-left">Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis
                        in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis
                        in, rhoncus ac.</p>
                    <p><strong class="caps">N</strong>ulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem
                        pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus
                        ac.
                    </p>
                </div>
                <div id="mec">
                    <p><span>MECANISMOS DE CONTACTO</span></p>
                    <p>Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac.</p>
                    <p class="paragraf-left">Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis
                        in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis
                        in, rhoncus ac.</p>
                    <p><strong class="caps">N</strong>ulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem
                        pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus ac. Nunc velit risus, dapibus non interdum quis, suscipit nec dolor. Duis in nibh id lorem pulvinar adipiscing. Nulla odio elit, rutrum at convallis in, rhoncus
                        ac.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>